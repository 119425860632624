app-root {

    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    &.native-scrolling{
      overflow: visible;
      height: initial;
    }
  }
  
